import React, {Component, Suspense} from 'react';
import {BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import '../static/css/common.less';
import Loading from "../common/component/loading";
const LuckyWheel = React.lazy(()=>import("./member/wheel"));
const Home = React.lazy(()=>import("./home"));
const NoticeList = React.lazy(()=>import("./notice/list"));
const ActivityList = React.lazy(()=>import("./activity/list"));
const ActivityInfo = React.lazy(()=>import("./activity/info"));
const ActivityZxh = React.lazy(()=>import("./activity/zxh"));
const MerchantList = React.lazy(()=>import("./payment/merchant"));
const GameList = React.lazy(()=>import("./game/list"));
const GameInfo = React.lazy(()=>import("./game/info"));
const Member = React.lazy(()=>import("./member/home"));
const Login = React.lazy(()=>import("./login"));
const BackgroundLogin = React.lazy(()=>import("./backgroundLogin"));
const Register = React.lazy(()=>import("./register"));
const MemberDetail = React.lazy(()=>import("./member/detail"));
const ForgetPassword = React.lazy(()=>import("./forgetPassword"));
const MemberPassword = React.lazy(()=>import("./member/password"));
const MemberCheckin = React.lazy(()=>import("./member/checkin"));
const Bank = React.lazy(()=>import("./member/bank"));
const PointRecord = React.lazy(()=>import("./member/pointRecord"));
const MemberInvite = React.lazy(()=>import("./member/invite"));
const MemberWinlose = React.lazy(()=>import("./member/winlose"));
const MemberWage = React.lazy(()=>import("./member/wage"));
const Gameplay = React.lazy(()=>import("./game/gameplay"));
const UserBetModel = React.lazy(()=>import("./game/model"));
const GameRecord = React.lazy(()=>import("./game/record"));
const GameAutoBet = React.lazy(()=>import("./game/auto"));
const MemberYuebao = React.lazy(()=>import("./member/yuebao"));
const RankList = React.lazy(()=>import("./rank"));
const MemberRank = React.lazy(()=>import("./member/rank"));
const MemberRelief = React.lazy(()=>import("./member/relief"));
const MemberLoan = React.lazy(()=>import("./member/loan"));
const MemberLoss = React.lazy(()=>import("./member/loss"));
const GoodsList = React.lazy(()=>import("./goods"));
const MemberCards = React.lazy(()=>import("./member/card"));
const GameTrend = React.lazy(()=>import("./game/trend"));
const Deposit = React.lazy(()=>import("./payment/deposit"));
const RedPacket = React.lazy(()=>import("./redpacket"));
const GameRoom = React.lazy(()=>import("./game/room"));
const MemberDownLine = React.lazy(()=>import("./member/downline"));
const MemberWageExchange = React.lazy(()=>import("./member/wageExchange"));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/notice/list" element={<NoticeList />} />
                        <Route exact path="/activity/list" element={<ActivityList />} />
                        <Route exact path="/activity/info/:id" element={<ActivityInfo />} />
                        <Route exact path="/activity/zxh" element={<ActivityZxh />} />
                        <Route exact path="/payment/merchant" element={<MerchantList />} />
                        <Route exact path="/payment/deposit" element={<Deposit />} />
                        <Route exact path="/game/room/:id" element={<GameRoom />} />
                        <Route exact path="/game/list" element={<GameList />} />
                        <Route exact path="/game/info/:id" element={<GameInfo />} />
                        <Route exact path="/game/help/:id" element={<Gameplay />} />
                        <Route exact path="/game/model/:id" element={<UserBetModel />} />
                        <Route exact path="/game/record/:id" element={<GameRecord />} />
                        <Route exact path="/game/auto/:id" element={<GameAutoBet />} />
                        <Route exact path="/game/trend/:id" element={<GameTrend />} />
                        <Route exact path="/member" element={<Member />} />
                        <Route exact path="/member/detail" element={<MemberDetail />} />
                        <Route exact path="/member/password" element={<MemberPassword />} />
                        <Route exact path="/member/checkin" element={<MemberCheckin />} />
                        <Route exact path="/member/bank" element={<Bank />} />
                        <Route exact path="/member/point/record" element={<PointRecord />} />
                        <Route exact path="/member/invite" element={<MemberInvite />} />
                        <Route exact path="/member/winlose" element={<MemberWinlose />} />
                        <Route exact path="/member/wage" element={<MemberWage />} />
                        <Route exact path="/member/yuebao" element={<MemberYuebao />} />
                        <Route exact path="/member/rank" element={<MemberRank />} />
                        <Route exact path="/member/relief" element={<MemberRelief />} />
                        <Route exact path="/member/loan" element={<MemberLoan />} />
                        <Route exact path="/member/loss" element={<MemberLoss />} />
                        <Route exact path="/member/cards" element={<MemberCards />} />
                        <Route exact path="/member/wheel" element={<LuckyWheel />} />
                        <Route exact path="/member/downline" element={<MemberDownLine />} />
                        <Route exact path="/member/wage/exchange" element={<MemberWageExchange />} />
                        <Route exact path="/red/packet" element={<RedPacket />} />
                        <Route exact path="/goods" element={<GoodsList />} />
                        <Route exact path="/rank" element={<RankList />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/user/background/login" element={<BackgroundLogin />} />
                        <Route exact path="/reg.php" element={<Register />} />
                        <Route exact path="/forget" element={<ForgetPassword />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        );
    }
}

export default App;

